import React, { useEffect, useState, useContext } from "react"
import { navigate } from "gatsby"
import { Flex, Box, Text, Image } from "rebass"
import { useForm } from "react-hook-form"
import qs from "qs"

import Popup from "../../components/popup"
import { useAuth } from "../../context/auth"
import LoginLayout from "../../components/login-layout"
import SEO from "../../components/seo"
import Button from "../../components/button"
import Spinner from "../../components/spinner"
import LoginButtons from "../../components/login-buttons"
import Graphic from "../../assets/login-graphic.png"

import MedusaClient from "../../services/api"

const GITHUB_CLIENT_ID = process.env.GATSBY_GITHUB_CLIENT_ID || ""
const GITHUB_REDIRECT_URI = process.env.GATSBY_GITHUB_REDIRECT_URI || ""

const IndexPage = ({ location }) => {
  const [status, setStatus] = useState("loading")
  const [loading, setLoading] = useState(false)
  const auth = useAuth()

  const [, ...search] = location.search
  const { cli_tok, req_tok } = qs.parse(search)

  const responseGoogle = response => {
    if (response.getAuthResponse) {
      setLoading(true)
      const res = response.getAuthResponse()
      const token = res.id_token

      setLoading(true)
      auth
        .handleLogin("google", {
          token
        })
        .then(async ({ data }) => {
          if (data.has_account) {
            await MedusaClient.cliAuth.authenticate(cli_tok, req_tok)
            navigate("/cli-login/done")
          } else {
            navigate(
              `/signup/google?${qs.stringify({
                ...data.details,
                next: "cli",
                cli_tok,
                req_tok,
                token
              })}`
            )
          }
        })
      setLoading(false)
    }
  }

  const handleAuthenticate = async () => {
    await MedusaClient.cliAuth.authenticate(cli_tok, req_tok)
    navigate("/cli-login/done")
  }

  const handleGithub = async () => {
    const { data } = await MedusaClient.auth.createCsrfToken({
      provider: "github",
      intent: "cli",
      cli_tok,
      req_tok
    })

    const params = {
      client_id: GITHUB_CLIENT_ID,
      redirect_uri: GITHUB_REDIRECT_URI,
      scope: "user",
      state: data.csrf
    }
    const qstring = Object.keys(params)
      .map(k => `${k}=${params[k]}`)
      .join("&")
    const loginUrl = `https://github.com/login/oauth/authorize?${qstring}`

    const popup = Popup.open("github-identity-cli", loginUrl, window, {
      height: 1000,
      width: 600
    })

    popup.then(
      res => {
        auth
          .handleLogin("github", {
            state: res.state,
            code: res.code
          })
          .then(async data => {
            if (data.has_account) {
              await MedusaClient.cliAuth.authenticate(cli_tok, req_tok)
              navigate("/cli-login/done")
            } else {
              navigate(
                `/signup/github?${qs.stringify({
                  ...data.details,
                  cli_tok,
                  req_tok,
                  next: "cli",
                  state: res.state
                })}`
              )
            }
          })
      },
      () => {}
    )
  }

  useEffect(() => {
    if (auth.isLoading) {
      return
    }

    if (auth.isLoggedIn) {
      setStatus("requires_confirmation")
    } else {
      setStatus("requires_login")
    }
  }, [auth.isLoading, auth.isLoggedIn])

  return (
    <LoginLayout pt={3}>
      <SEO title="Login" />
      <Text mb={4} fontWeight="bold" fontSize={4}>
        Medusa CLI Login
      </Text>
      {status === "loading" && (
        <Flex justifyContent="center">
          <Spinner dark width="20px" height="20px" />
        </Flex>
      )}
      {status === "requires_confirmation" && (
        <Flex sx={{ flexDirection: "column", width: "100%" }}>
          <Flex sx={{ alignItems: "baseline" }} mb={4}>
            <Text fontSize={1}>Logged in as</Text>
            <Text ml={1} fontWeight="500">
              {auth.user.first_name} {auth.user.last_name}
            </Text>
          </Flex>
          <Button onClick={handleAuthenticate} variant="cta">
            Confirm CLI authentication
          </Button>
        </Flex>
      )}
      {status === "requires_login" && (
        <>
          <LoginButtons
            setLoading={setLoading}
            onGithub={handleGithub}
            onEmail={() => navigate(`/cli-login/email${location.search}`)}
            onGoogleSucess={responseGoogle}
            onGoogleFailure={responseGoogle}
          />
          <Text fontSize={0} mt={1}>
            Don't have an account? Click an option above to sign up
          </Text>
        </>
      )}
    </LoginLayout>
  )
}

export default IndexPage
